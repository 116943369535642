<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Produtos</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="./produtos/new-product"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; Novo Produto</a
                  >
                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Produto</th>
                    <th>Categoria</th>
                    <th>Valor Delivery</th>
                    <th>Valor Ifood</th>
                    <th>Código</th>
                    <th>Status</th>
                    <th>Opçòes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(produto,index) in produtos" :key="index">
                    <td>
                      <div class="d-flex">
                        <h6 class="my-auto ms-3">{{ produto.nome_produto }}</h6>
                      </div>
                    </td>
                    <td class="text-sm">{{produto.categoria ? produto.categoria.nome : ''}}</td>
                    <td class="text-sm">{{produto.valores &&  produto.valores[1] && produto.valores[1].valor ? produto.valores[1].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})  : 0}}</td>
                    <td class="text-sm">{{produto.valores &&  produto.valores[2] && produto.valores[2].valor ? produto.valores[2].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})  : 0}}</td>
                    <td class="text-sm">634729</td>
                    <td>
                      <span class="badge badge-success badge-sm">{{produto.status ? 'Ativo' : 'Inativo'}}</span>
                    </td>
                    <td class="text-sm">
                      <a
                        :href="'./produtos/edit-product/'+ produto.produto_id"
                        class="mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Edit product"
                      >
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Produto</th>
                    <th>Categoria</th>
                    <th>Valor Delivery</th>
                    <th>Valor Ifood</th>
                    <th>Código</th>
                    <th>Status</th>
                    <th>Opçòes</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
// import setTooltip from "@/assets/js/tooltip.js";
  import "@/assets/scss-new/soft-ui-dashboard.scss";

import "@/assets/css/nucleo-icons.css";
import "@/assets/css/nucleo-svg.css";
	import store from '@/store'

export default {
  name: "ProductsList",

  data: () => ({
			// variável para a mensagem de resposta
			produtos: {},
      dataTableSearch:null,
    }), 
  methods: {
			async buscaProdutos(){
				var resp = await store.dispatch('getProdutos',{});
				this.produtos = resp.data.dados
        // console.log(this.produtos[0])
        // this.dataTableSearch.data = resp.data.dados.data
        console.log(this.produtos)
        // for (let index = 0; index < resp.data.dados.data.length; index++) {
          // const produto = resp.data.dados.data[index];
          // this.produtos.push([
          //   '<div class="d-flex">\
          //       <img\
          //         class="w-10 ms-3"\
          //         src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/adidas-hoodie.jpg"\
          //         alt="hoodie"\
          //       />\
          //       <h6 class="my-auto ms-3"></h6>\
          //     </div>',
          //     '<th>Categoria</th>',
          //     '<th>Valores</th>',
          //     '<th>Código</th>',
          //     '<th>Situação</th>',
          //     '<th>Opções</th>'
          // ])
        // }
			},
      async initDatables(){
        if (document.getElementById("products-list")) {
          this.dataTableSearch = new DataTable("#products-list", {
            searchable: true,
            fixedHeight: false,
            perPage: 10
          });

          console.log(this.dataTableSearch,'aqui')
    
          document.querySelectorAll(".export").forEach(function (el) {
            el.addEventListener("click", function () {
              var type = el.dataset.type;
    
              var data = {
                type: type,
                filename: "soft-ui-" + type
              };
    
              if (type === "csv") {
                data.columnDelimiter = "|";
              }
    
              this.dataTableSearch.export(data);
            });
          });
        }
      }
    },
  async mounted() {
    await this.buscaProdutos()
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 10
      });
    

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    // setTooltip(this.$store.state.bootstrap);
  }
};
</script>
